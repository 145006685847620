var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "justify-center", attrs: { align: "center" } },
    [
      _c("div", { staticClass: "text-h4" }, [
        _c("br"),
        _vm._v(
          " Scheda Cliente " +
            _vm._s(_vm.CapitalizeString(_vm.cliente_selezionato.cognome)) +
            " " +
            _vm._s(_vm.CapitalizeString(_vm.cliente_selezionato.nome)) +
            " "
        ),
      ]),
      _c("br"),
      _c(
        "div",
        { staticClass: "q-gutter-y-md", staticStyle: { width: "100%" } },
        [
          _c(
            "q-card",
            { attrs: { flat: "" } },
            [
              _c(
                "q-tabs",
                {
                  attrs: { "inline-label": "", align: "center" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("q-tab", {
                    attrs: {
                      name: "dati_anagrafici",
                      label: "Dati anagrafici",
                    },
                  }),
                  _c("q-tab", {
                    staticClass: "text-weight-bolder",
                    attrs: { name: "preventivi", label: "Elenco Preventivi" },
                  }),
                  _c("q-tab", {
                    staticClass: "text-weight-bolder",
                    attrs: { name: "pratiche", label: "Elenco Pratiche" },
                  }),
                ],
                1
              ),
              _c(
                "q-tab-panels",
                {
                  ref: "tabs",
                  attrs: {
                    animated: "",
                    "transition-prev": "jump-down",
                    "transition-next": "jump-down",
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("q-tab-panel", { attrs: { name: "dati_anagrafici" } }, [
                    _c("div", { staticClass: "row justify-center" }, [
                      _c("div", { staticClass: "col-12 col-md-5" }, [
                        _c("fieldset", [
                          _c("legend", [
                            _c("strong", [_vm._v("Informazioni generali")]),
                          ]),
                          _c("table", { staticClass: "table" }, [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("ID:"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.cliente_selezionato.id)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Tipo persona:"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(
                                    _vm._s(_vm.cliente_selezionato.tipo_persona)
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Codice Fiscale:"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.cliente_selezionato.codice_fiscale
                                    )
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Partita IVA:"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(
                                    _vm._s(_vm.cliente_selezionato.partita_iva)
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Sesso:"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.cliente_selezionato.sesso)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Data di nascita:"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(
                                    _vm._s(_vm.cliente_selezionato.data_nascita)
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Luogo di nascita:"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.cliente_selezionato.luogo_nascita
                                    )
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("professione:"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.cliente_selezionato.altri_dati[0]
                                        .professione
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("br"),
                      ]),
                      _c("div", { staticClass: "col-12 col-md-5" }, [
                        _c("fieldset", [
                          _c("legend", [_c("strong", [_vm._v("Recapiti")])]),
                          _c("br"),
                          _c(
                            "table",
                            { staticClass: "table table-bordered" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Unità Operativa")]),
                                  _c("th", [_vm._v("Descrizione")]),
                                  _c("th", [_vm._v("Valore")]),
                                ]),
                              ]),
                              _vm._l(
                                _vm.cliente_selezionato.altri_dati,
                                function (altri_dati, i) {
                                  return _c(
                                    "tbody",
                                    { key: i },
                                    _vm._l(
                                      altri_dati.recapiti,
                                      function (recapito, j) {
                                        return _c("tr", { key: j }, [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.limitaLength(
                                                  altri_dati.unita_operativa
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(recapito.tipo)),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(recapito.value)),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                }
                              ),
                              _vm.cliente_selezionato.altri_dati.length === 0
                                ? _c("tfoot", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            colspan: "3",
                                            align: "center",
                                          },
                                        },
                                        [_vm._v("Nessun recapito identificato")]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c("br"),
                        ]),
                        _c("br"),
                      ]),
                      _c("div", { staticClass: "col-12 col-md-5" }, [
                        _c("fieldset", [
                          _c("legend", [
                            _c("strong", [
                              _vm._v("Indirizzi Residenza / Sede legale"),
                            ]),
                          ]),
                          _c("br"),
                          _c("table", { staticClass: "table table-bordered" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("Unità Operativa")]),
                                _c("th", [_vm._v("Indirizzo")]),
                                _c("th", [_vm._v("Civico")]),
                                _c("th", [_vm._v("Comune")]),
                                _c("th", [_vm._v("Provincia")]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm._l(
                                  _vm.indirizzi_residenza,
                                  function (indirizzo, i) {
                                    return _c("tr", { key: i }, [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.limitaLength(
                                              indirizzo.unita_operativa
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(indirizzo.indirizzo)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(indirizzo.civico)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(indirizzo.comune)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(indirizzo.provincia)),
                                      ]),
                                    ])
                                  }
                                ),
                                _vm.indirizzi_residenza.length === 0
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            colspan: "5",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Nessun indirizzo identificato"
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _c("br"),
                        ]),
                        _c("br"),
                      ]),
                      _c("div", { staticClass: "col-12 col-md-5" }, [
                        _c("fieldset", [
                          _c("legend", [
                            _c("strong", [_vm._v("Indirizzi Corrispondenza")]),
                          ]),
                          _c("br"),
                          _c("table", { staticClass: "table table-bordered" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("Unità Operativa")]),
                                _c("th", [_vm._v("Indirizzo")]),
                                _c("th", [_vm._v("Civico")]),
                                _c("th", [_vm._v("Comune")]),
                                _c("th", [_vm._v("Provincia")]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm._l(
                                  _vm.indirizzi_corrispondenza,
                                  function (indirizzo, i) {
                                    return _c("tr", { key: i }, [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.limitaLength(
                                              indirizzo.unita_operativa
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(indirizzo.indirizzo)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(indirizzo.civico)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(indirizzo.comune)),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(indirizzo.provincia)),
                                      ]),
                                    ])
                                  }
                                ),
                                _vm.indirizzi_corrispondenza.length === 0
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          attrs: {
                                            colspan: "5",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "Nessun indirizzo identificato"
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                          _c("br"),
                        ]),
                        _c("br"),
                      ]),
                    ]),
                  ]),
                  _c(
                    "q-tab-panel",
                    { attrs: { name: "preventivi" } },
                    [
                      _c("q-table", {
                        staticClass:
                          "my-sticky-header-table no-box-shadow q-mt-none",
                        attrs: {
                          title:
                            "Clicca su un preventivo per attivare le opzioni di gestione",
                          data: _vm.righe_preventivi_cliente,
                          columns: _vm.colonne_preventivi_cliente,
                          filter: _vm.filter,
                          dense: "",
                          "row-key": "name",
                        },
                        on: { "row-click": _vm.selezionaRecordPreventivi },
                        scopedSlots: _vm._u([
                          {
                            key: "top-right",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "panel-cerca" },
                                  [
                                    _c("q-input", {
                                      staticStyle: { "min-width": "350px" },
                                      attrs: {
                                        borderless: "",
                                        dense: "",
                                        debounce: "300",
                                        placeholder: "Cerca",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c("q-icon", {
                                                attrs: { name: "search" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                      model: {
                                        value: _vm.filter,
                                        callback: function ($$v) {
                                          _vm.filter = $$v
                                        },
                                        expression: "filter",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "header",
                            fn: function (props) {
                              return [
                                _c(
                                  "q-tr",
                                  { attrs: { props: props } },
                                  _vm._l(props.cols, function (col) {
                                    return _c(
                                      "q-th",
                                      {
                                        key: col.name,
                                        staticStyle: {
                                          "font-size": "14px",
                                          "border-bottom": "1px SOLID #404040",
                                          "background-color": "#ffcc99",
                                          color: "#000",
                                        },
                                        attrs: { props: props },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(col.label)),
                                        ]),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-tab-panel",
                    { attrs: { name: "pratiche" } },
                    [
                      _c("q-table", {
                        staticClass:
                          "my-sticky-header-table no-box-shadow q-mt-none",
                        attrs: {
                          title:
                            "Clicca su una pratica per attivare le opzioni di gestione",
                          data: _vm.righe_pratiche_cliente,
                          columns: _vm.colonne_pratiche_cliente,
                          filter: _vm.filter,
                          dense: "",
                          "row-key": "name",
                        },
                        on: { "row-click": _vm.selezionaRecordPratiche },
                        scopedSlots: _vm._u([
                          {
                            key: "top-right",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "panel-cerca" },
                                  [
                                    _c("q-input", {
                                      staticStyle: { "min-width": "350px" },
                                      attrs: {
                                        borderless: "",
                                        dense: "",
                                        debounce: "300",
                                        placeholder: "Cerca",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append",
                                          fn: function () {
                                            return [
                                              _c("q-icon", {
                                                attrs: { name: "search" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                      model: {
                                        value: _vm.filter,
                                        callback: function ($$v) {
                                          _vm.filter = $$v
                                        },
                                        expression: "filter",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "header",
                            fn: function (props) {
                              return [
                                _c(
                                  "q-tr",
                                  { attrs: { props: props } },
                                  _vm._l(props.cols, function (col) {
                                    return _c(
                                      "q-th",
                                      {
                                        key: col.name,
                                        staticStyle: {
                                          "font-size": "14px",
                                          "border-bottom": "1px SOLID #404040",
                                          "background-color": "#ffcc99",
                                          color: "#000",
                                        },
                                        attrs: { props: props },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(col.label)),
                                        ]),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c("hr", { staticStyle: { color: "#fff" } }),
      _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }